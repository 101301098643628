<template>

  <span v-if="!is_westudy">
      <dashBusinessAdmin   v-if="showBusinessToOwner" />
      <dashBusinessManager v-else-if="showBusinessToManager" />
      <dashBusinessUser    v-else />
  </span>
  <span v-else-if="is_westudy">
      <dashBusinessAdminWestudy   v-if="showBusinessToOwner" />
      <dashBusinessManager        v-else-if="showBusinessToManager" />
      <dashBusinessWestudyUser    v-else />
  </span>

</template>

<script>
import dashBusinessAdmin from "@/components/pages/businessDashboard/dashBusiness/dashBusinessAdmin";
import dashBusinessAdminWestudy from "@/components/pages/businessDashboard/dashBusiness/dashBusinessAdminWestudy";
import dashBusinessUser from "@/components/pages/businessDashboard/dashBusiness/dashBusinessUser";
import dashBusinessManager from "@/components/pages/businessDashboard/dashBusiness/dashBusinessManager";
import dashBusinessWestudyUser from "@/components/pages/businessDashboard/dashBusiness/dashBusinessWestudyUser";
import {mapState} from "vuex";
export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    dashBusinessAdmin,
    dashBusinessUser,
    dashBusinessManager,
    dashBusinessAdminWestudy,
    dashBusinessWestudyUser,
  },
  data() {
    return {
      business : {}
    }
  },
  computed : {
    ...mapState('dashboard', ['sideMenu','showBusinessToManager','showBusinessToOwner']),
  }
}
</script>

<style scoped>

</style>